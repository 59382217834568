<template>
  <div>
    <CContainer fluid>
      <IkoCartas
        :cartas="cartas"
        name="product"
        :loading="loading"
        @deleteCarta="eliminarCarta"
        @criarModal="criarModal"
        @editarModal="editarModal"
      ></IkoCartas>
    </CContainer>

    <CModal
      :title="`${modal.type} Product`"
      :show.sync="modal.open"
      :color="modal.type === 'Edit' ? 'warning' : 'success'"
    >
      <CSpinner v-show="!propsFetched || !brandsFetched"></CSpinner>
      <div v-show="propsFetched && brandsFetched">
        <CCol sm="12">
          <CInput
            v-model="form.name"
            label="Name"
            placeholder="Product Name"
          />
        </CCol>

        <CCol sm="12">
          <CInput
            v-model="form.description"
            label="Description"
            type="textarea"
            placeholder="Product Description"
          />
        </CCol>

        <CCol sm="12">
          <CInput
            v-model="form.url"
            label="URL"
            placeholder="Product URL"
          />
        </CCol>

        <CCol sm="12">
          <CInput
            v-model="form.price"
            label="Price"
            placeholder="Product Price"
            type="number"
          />
        </CCol>

        <CCol sm="12">
          <CTextarea
            v-model="form.images"
            label="Images (separate by newline)"
            placeholder="Images"
          />
        </CCol>

        <CCol sm="12">
          <label>Brand</label>
          <multiselect v-model="form.brand" :options="brands" placeholder="Select one" label="name" track-by="name"></multiselect>
        </CCol>

        <CCol sm="12">
          <label class="typo__label">Props</label>
          <multiselect v-model="form.props" :options="props" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select some" label="name" track-by="name" :preselect-first="true">
            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
          </multiselect>
        </CCol>
      </div>

      <template #footer>
          <CButton @click="modal.open = false" color="danger">Cancel</CButton>
          <CButton @click="modalSubmit" :disabled="aSubmeter || !propsFetched || !brandsFetched" color="success">Submit</CButton>
        </template>
    </CModal>
  </div>
</template>

<script>
import { getProductsFromCategory, postProductInCategory, deleteProduct, putProduct } from '@/api/products';
import { getProps } from '@/api/props';

import IkoCartas from '@/components/IkoCartas.vue';
import Multiselect from 'vue-multiselect';
import {getBrands} from "@/api/brands";

export default {
  name: 'CategoryProducts',
  components: {
    IkoCartas, Multiselect
  },
  data: () => ({
    modal: {
      type: 'Edit',
      open: false
    },
    form: {},
    aSubmeter: false,
    loading: true,
    cartas: [],
    props: [],
    brands: [],
    propsFetched: false,
    brandsFetched: false
  }),
  methods: {
    fetchNecessary() {
      this.propsFetched = false;
      this.brandsFetched = false;
      getProps().then(props => this.props = props).finally(() => this.propsFetched = true);
      getBrands().then(brands => this.brands = brands).finally(() => this.brandsFetched = true);
    },
    editarModal(idx) {
      if (!this.propsFetched || !this.brandsFetched) this.fetchNecessary();
      this.form = {...this.cartas[idx].data};
      this.form.images = this.form.images.join('\n')
      this.modal.type = 'Edit';
      this.modal.open = true;
    },
    criarModal() {
      if (!this.propsFetched || !this.brandsFetched) this.fetchNecessary();
      this.form = {};
      this.modal.type = 'New';
      this.modal.open = true;
    },
    addCarta(carta) {
      this.cartas.push({
          data: carta,
          header: `<a href="${carta.url}">${carta.name}</a>`,
          text: `<b>Description</b>: ${carta.description}<br/><b>Price</b>: ${carta.price}$<br/><b>Images</b>: ${(carta.images ?? []).map((url, i) => `<a href="${url}">${i+1}</a>`).join(', ')}<br/><b>Brand</b>: ${carta.brand?.name ? `<a href="/#/brands/${carta.brand.name}">${carta.brand.name}</a>` : 'unknown'}<br/><b>Props</b>: ${carta.props.length ? carta.props.map(p => `<a href="/#/props/${p.name}">${p.name}</a>`).join(', ') : 'unknown'}`,
      });
    },
    modalSubmit() {
      this.aSubmeter = true;
      this.form.images = this.form.images.split('\n');

      if(this.modal.type === 'Edit') {
        putProduct(this.form.id, this.form).then(() => {
          this.carregarCartas();
          this.form = {};
          this.modal.open = false;
          this.aSubmeter = false;
        });
      } else {
        postProductInCategory(this.$route.params.category, this.form).then(() => {
          this.modal.open = false;
          this.addCarta(this.form);
          this.form = {};
          this.aSubmeter = false
        });
      }
    },
    eliminarCarta(idx) {
      deleteProduct(this.cartas[idx].data.id).then(() => {
        this.cartas.splice(idx, 1);
        this.aSubmeter = false;
        this.eliminarModal = false;
      });
    },
    carregarCartas() {
      this.loading = true;
      this.cartas = [];
      getProductsFromCategory(this.$route.params.category, ['props', 'brand']).then((products) => {
        products = products.sort((a, b) => a.name - b.name);
        products.forEach(brand => this.addCarta(brand));
        this.loading = false;
      }).catch(() => this.loading = false);
    }
  },
  mounted() {
    this.carregarCartas();
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>